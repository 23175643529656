<template>
      <v-dialog :value="ledgerEntryModal" persistent hide-overlay width="700px">
              <v-card class="pa-2">
            <v-card-text>
          <div class="d-flex">
            <date-picker
              :dense="true"
              :outlined="true"
              :filled="true"
              :value="thisEntry.date"
              label="Datum"
              :dateFormatted="thisEntry.dateFormatted"
              @changeDate="changeDate"
            ></date-picker>
            <v-select
              outlined
              filled
              label="Type"
              :items="types"
              v-model="thisEntry.type"
            ></v-select>
            <v-text-field
              outlined
              filled
              label="Omschrijving"
              v-model="thisEntry.description"
            ></v-text-field>
            <v-text-field
              outlined
              filled
              label="Bedrag"
              v-model="thisEntry.amount"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn color="primary" @click="closeLedgerEntryModal">Annuleer</v-btn>
             <v-btn color="primary" @click="updateLedgerEntry">Opslaan</v-btn>
             </v-card-actions>
              </v-card>
          </v-dialog>
</template>

<script>
import datePicker from '@/components/datePicker.vue';
export default {
    name:"ledgerEntryModal",
    props: {ledgerEntryModal: Boolean,entry: Object},
    data(){
        return{
            thisEntry: JSON.parse(JSON.stringify(this.entry)),
            types: ["Inkomsten", "Uitgaven"],
        }
    },
    methods:{
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
         changeDate(date) {
      this.thisEntry.date = date;
      this.thisEntry.dateFormatted = this.formatDate(date);
    },
updateLedgerEntry(){
    this.$emit("update-ledger-entry",this.thisEntry)
},
closeLedgerEntryModal(){
    this.$emit("close-ledger-entry-modal")
}
    },
    components: {
        datePicker,
    },
}
</script>

<style>

</style>