<template>
  <div>
    <v-card>
      <form @keydown.enter="saveEntry">
        <v-card-title>Kasboek</v-card-title>
        <v-card-text>
          <div class="d-flex">
            <div>
              <date-picker
                :dense="true"
                :outlined="true"
                :filled="true"
                :value="entry.date"
                label="Datum"
                :dateFormatted="entry.dateFormatted"
                @changeDate="changeDate"
              ></date-picker>
              <div class="d-flex justify-center ma-2">
                <v-btn
                  class="mr-1"
                  color="primary"
                  x-small
                  @click="addToDate(-1)"
                  ><h2>-1</h2></v-btn
                >
                <v-btn color="primary" x-small @click="addToDate(1)"
                  ><h2>+1</h2></v-btn
                >
                <v-checkbox
                  v-model="resetDate"
                  label="Reset datum"
                ></v-checkbox>
              </div>
            </div>
            <v-select
              outlined
              filled
              label="Type"
              :items="types"
              v-model="entry.type"
            ></v-select>
            <v-text-field
              outlined
              filled
              label="Omschrijving"
              v-model="entry.description"
            ></v-text-field>
            <v-text-field
              outlined
              filled
              label="Bedrag"
              v-model="entry.amount"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <div class="text-h4 font-weight-bold mr-5">Saldo: {{ toCurrency(balance) }}</div>
          <v-btn-toggle>
            <v-btn color="primary" @click="insertLedgerEntry">Opslaan</v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </form>
    </v-card>
    <div style="height:100px;"></div>
    <v-card>
      <v-card-title>Boekingen</v-card-title>
      <v-card-text>
        <div
          class="d-flex block-wrappper"
          v-for="(entry, index) in entries"
          :key="index"
        >
          <div class="block">{{ toDateString(entry.date) }}</div>
          <div class="block">{{ entry.type }}</div>
          <div class="block">{{ entry.description }}</div>
          <div class="block">{{ toCurrency(entry.amount) }}</div>
          <div>
            <div class="d-flex">
              <a @click="openLedgerEntryModal(entry)"
                ><v-icon>mdi-pencil</v-icon></a
              >
              <div style="width:30px"></div>
              <a @click="deleteLedgerEntry(entry._id)"
                ><v-icon>mdi-delete</v-icon></a
              >
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <ledger-entry-modal
      v-if="ledgerEntryModal"
      :entry="selectedEntry"
      :ledgerEntryModal="ledgerEntryModal"
      @update-ledger-entry="updateLedgerEntry"
      @close-ledger-entry-modal="closeLedgerEntryModal"
    >
    </ledger-entry-modal>
  </div>
</template>
<script>
import { fetchGET, fetchPOST, toCurrency, toDateString } from "../js/functions";
import datePicker from "@/components/datePicker.vue";
import ledgerEntryModal from "@/components/ledgerEntryModal.vue";
export default {
  name: "ledger",
  data() {
    return {
      entry: {
        type: "",
        amount: '',
        description: "",
        date: new Date(Date.now()).toISOString().split("T")[0],
        dateFormatted: this.formatDate(new Date().toISOString().split("T")[0])
      },
      selectedEntry: {},
      entries: [],
      types: ["Inkomsten", "Uitgaven"],
      ledgerEntryModal: false,
      selectedYear: 2023,
      balance: 0,
      resetDate: false
    };
  },
  methods: {
    toDateString(val) {
      return toDateString(val);
    },
    toCurrency(val) {
      return toCurrency(val);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    changeDate(date) {
      this.entry.date = date;
      this.entry.dateFormatted = this.formatDate(date);
      this.fetchLedgerEntries();
    },
    addToDate(n) {
      let newDate = new Date(this.entry.date);
      newDate.setDate(newDate.getDate() + n);
      let date = newDate.toISOString().split("T")[0];
      this.changeDate(date);
    },
    async fetchLedgerEntries() {
      let response = await fetchGET("fetchLedgerEntries", {
        date: this.entry.date
      });
      this.entries = response;
      this.ledgerTotals();
    },
    async insertLedgerEntry() {
      try {
        let amount = parseFloat(this.entry.amount.replace(",", "."));
        this.entry.amount =
          this.entry.type == "Inkomsten" ? amount : amount * -1;
        await fetchPOST("insertLedgerEntry", this.entry);
        this.entries.push(this.entry);
        this.resetEntry();
        this.ledgerTotals();
      } catch (e) {
        console.error(e);
      }
    },
    openLedgerEntryModal(entry) {
      this.selectedEntry = entry;
      this.ledgerEntryModal = true;
    },
    closeLedgerEntryModal() {
      this.selectedEntry = {};
      this.ledgerEntryModal = false;
    },
    async updateLedgerEntry(entry) {
      try {
        console.log(typeof entry.amount)
        let amount = parseFloat(entry.amount.replace(",", "."));
        entry.amount = entry.type == "Inkomsten" ? amount : amount * -1;
        await fetchPOST("updateLedgerEntry", entry);
        let i = 0;
        for (let thisEntry of this.entries) {
          if (thisEntry._id == entry._id) {
            if (thisEntry.date == entry.date) Object.assign(thisEntry, entry);
            else this.entries.splice(i, 1);
          }
          i++;
        }
        this.closeLedgerEntryModal();
        this.ledgerTotals();
      } catch (e) {
        console.error(e);
      }
    },
    async deleteLedgerEntry(id) {
      try {
        await fetchPOST("deleteLedgerEntry", { _id: id });
        let index = this.entries.findIndex(entry => entry._id == id);
        if (index > -1) this.entries.splice(index, 1);
        this.ledgerTotals();
      } catch (e) {
        console.error(e);
      }
    },
    resetEntry() {
      let date;
      let dateFormatted;
      if (!this.resetDate) {
        date = this.entry.date;
        dateFormatted = this.entry.dateFormatted;
      }
      this.entry = {
        type: "",
        amount: 0,
        description: "",
        date: new Date(Date.now()).toISOString().split("T")[0],
        dateFormatted: this.formatDate(new Date().toISOString().split("T")[0])
      };
      if (!this.resetDate) {
        this.entry.date = date;
        this.entry.dateFormatted = dateFormatted;
      }
    },
    async ledgerTotals() {
      try {
        let response = await fetchGET("ledgerTotals", {
          year: this.selectedYear,
          toDate: this.entry.date
        });
        console.log(response);
        this.balance = response.balance;
      } catch (e) {
        console.error(e);
      }
    }
  },
  mounted() {
    this.fetchLedgerEntries();
  },
  components: {
    datePicker,
    ledgerEntryModal
  }
};
</script>
<style scoped>
.block {
  width: 33%;
  margin: 10px;
}
</style>